body {
  max-width: 480px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grid-container {
  display: grid;
  grid-template-columns: 2.4fr repeat(6, 1fr);
  grid-auto-rows: 32px;
  grid-gap: 4px;
  margin: 32px 8px;

  .grid-hr {
    grid-column-start: 1;
    grid-column-end: 8;
    text-align: center;
  }

  .grid-item {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .lightgray {
    background-color: lightgray;
  }

  .lightblue {
    background-color: lightblue;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  gap: 20px;

  .btn {
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    font-weight: bold;
  }
}
